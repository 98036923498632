/**
* Welcome to your ✨𝔲𝔱𝔦𝔩𝔦𝔱𝔶 𝔰𝔱𝔶𝔩𝔢𝔰✨!
* This file includes utility classes that are super simple
* and can be used to add general styles; variable definitions
* for colors; and styles to html, body, and other high level
* DOMs.
*
* All utility classes start with a `u-` and all do
* one basic CSS thing (for example, making the font-weight
* 600 for bolding) or are super generic.
*
* This is 𝙉𝙊𝙏 the place to define classes for components or
* do rigorous styling. You should not need to change this file
* much after initial creation.
*/

@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,600");

:root {
  --primary: #396dff;
  --primary--dim: #6987db;
  --darkgrey: #666;
  --grey: #999;
  --white: #fff;
  --black: #000;

  --xs: 5px;
  --s: 11px;
  --m: 17px;
  --l: 24px;
  --left: 180px;
  --shadow:
          1px 1px 10px rgba(0, 0, 0, 0.07),
          2px 2px 20px rgba(0, 0, 0, 0.10),
          4px 4px 27px rgba(0, 0, 0, 0.12),
          8px 8px 38px rgba(0, 0, 0, 0.15),
          21px 21px 75px rgba(0, 0, 0, 0.22);
}

body {
  margin: 0;
  padding: 0;
  font-size: var(--m);
}

form {
  flex-grow: 1;
}

.u-flex {
  display: flex;
}

.u-flexColumn {
  display: flex;
  flex-direction: column;
}

.u-flex-justifyCenter {
  justify-content: center;
}

.u-flex-alignCenter {
  align-items: center;
}

.u-inlineBlock {
  display: inline-block;
}

.u-bold {
  font-weight: 600;
}

.u-grey {
  color: var(--grey);
  font-size: var(--s);
}

.u-textCenter {
  text-align: center;
}
.u-caption {
  color: var(--grey);
  font-size: var(--s);
}
.u-textRight {
  text-align: right;
}

.u-pointer {
  cursor: pointer;
}

.u-link-bib {
  color: var(--darkgrey);
  text-decoration: none;
  cursor: pointer;
}

.u-link-bib:hover {
  text-decoration: underline;
}

.u-img-container, .u-img-container-full, .u-img-container-center {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 800px;
  display: inline-flex;
  flex-direction: column;
}

.u-img-container > img {
  margin: var(--s) auto;
  width: 100%;
  box-shadow: var(--shadow);
}


.u-img-container-center > img {
  width: 90%;
  min-width: 300px;
}

.u-img-container-full > * {
  min-width: 100%;
}

.u-media {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
  box-shadow: var(--shadow);
}

.u-media iframe {
  position: absolute;
  border: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.u-no-shadow {
  box-shadow: none;
}

.u-no-shadow > img{
  box-shadow: none;
}

h3 {
  text-decoration: underline;
  margin: 50px 0 40px 0;
}
