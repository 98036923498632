@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@400');
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Suisse Screen";
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  /* src: url('./fonts/ABCMonumentGrotesk-Medium-Trial.woff') format('woff'); */
  src: url('./fonts/SuisseIntl-Book.woff2') format('woff2');
}

/* @font-face {
  font-family: Marist;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url('./fonts/ABCMarist-RegularItalic.woff') format('woff');
  src: url('./fonts/ABCMarist-RegularItalic.woff2') format('woff2');
} */

body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Suisse Screen", 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: gainsboro;
}

p {
  margin-top: 10px;
  margin-bottom: 10px;
}
