.Project-container-c {
    float: left;
    position: relative;
    width: auto;
    /*max-width: 600px;*/
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    direction: rtl;
}

.Project-container {
    padding: 12px;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    /*transform: translateX(-50%);*/
    font-size: 1rem;
    hyphens: auto;
    hyphenate-limit-chars: 10;
    direction: ltr;
}
h4 {
    font-weight: bold;
}
::-webkit-scrollbar {
    width: 2px;
    height: 2em;
}
::-webkit-scrollbar-thumb {
    background: #003cff;
}

.Project-header {
    font-family: "Ubuntu Mono", monospace;
    margin-bottom: 20px;
    /*font-weight: bold;*/
    font-size: 0.8rem;
    text-transform: uppercase;
    /* color: var(--grey); */
    /*line-height: 1rem;*/
    /*color: var(--grey);*/
}

.Project-title {
    font-size: var(--s);
    line-height: var(--s);
}




