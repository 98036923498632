.NavBar-container {
  width: calc(var(--left) - 2 * var(--l));
  height: calc(100vh - 2 * var(--l));
  position: fixed;
}

@media (max-width: 600px) {
  .NavBar-container {
    width: 100vw;
  }
}
a {
  cursor: pointer;
}
